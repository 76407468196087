html,
body {
  background-color: #161616;
  color: #F4511E;
  height: 100%;
  overflow-x: hidden;
  font-family: 'Chakra Petch', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #161616;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.text-theme-red {
  color: #F4511E !important;
}

.btn-theme-red-noborder {
  border: 0px;
  color: #F4511E;
}

.btn-theme-red {
  border: 1px solid #F4511E;
  color: #F4511E;
}

.btn-theme-red-filled {
  border: 1px solid #F4511E;
  color: #000000;
  background-color: #F4511E;
}

.btn-theme-red:hover {
  color: #F4511E;
}

.btn-theme-red:focus {
  box-shadow: 0 0 5px rgba(244, 81, 30, 1);
}

.dropdown-theme-red {
  border: 1px solid #F4511E;
  color: #F4511E;
  background-color: #161616;
}

.dropdown-theme-red a {
  color: #F4511E;
  border-bottom: 1px dotted rgba(244, 81, 30, .25);
}

.dropdown-theme-red a:last-child {
  border-bottom: 0px;
}

.dropdown-theme-red a:hover {
  color: #F4511E;
  background-color: rgba(244, 81, 30, .1);
}

.dropdown-theme-red li {
  border-bottom: 1px dotted rgba(244, 81, 30, .25);
}

.dropdown-theme-red li:last-child {
  border-bottom: 0px;
}

.desktop-icon {
  width: 75px;
  height: 75px;
  position: relative;
}

.desktop-icon .title {
  display: block;
  position: absolute;
  left: 0%;
  width: 75px;
  top: 75px;
  font-size: 8pt;
  line-height: 1;
}


.desktop-icon.v2 {
  color: #e0e0e0;
  border-color: rgba(255,255,255,.1);
  background-color: rgba(255,255,255,.05);
}

.desktop-icon.v2 .icon {
  color: #808080;
}

.desktop-icon.v2 .subicon {
  color: #808080;
  position: absolute;
  bottom: 0;
  right: 4px;
}

.box {
  color: #e0e0e0;
  border-radius: 5px;
  border: 1px solid rgba(255,255,255,.1);
  background-color: rgba(255,255,255,.05);
  padding: 5px 10px 5px 10px;
}